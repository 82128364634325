@import 'styles/colors.scss';

.alert {
  font-size: 15px;
  padding: 4px 8px;
  border-radius: 6px;
  color: white;

  &.success {
    text-align: center;
    background-color: $lightgreen;
  }

  &.info {
    text-align: center;
    background-color: $primary-light;
  }
}
