@import 'styles/colors.scss';

.file-uploader {
  .subheader {
    font-size: 12px;
    text-transform: uppercase;
    color: $light-text;
  }

  .file-list {
    h3 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $dark-text;
    }

    button {
      color: $dark-text;
    }
  }

  .hidden {
    display: none;
  }
}
