@mixin responsive-table {
  overflow-x: auto;
  display: block;
}

@mixin breakpoint($point, $direction: 'down') {
  $resolutions: (
    'xs': 0px,
    'sm': 650px,
    'md': 960px,
    'lg': 1280px,
    'xl': 1920px
  );

  $directions: (
    'up': 'min-width',
    'down': 'max-width'
  );

  @media (map-get($directions, $direction): map-get($resolutions, $point)) {
    @content;
  }
}
