@import 'styles/colors.scss';

.autocomplete {
  position: relative;

  label.MuiFormLabel-filled {
    background-color: white;
  }

  label + .MuiInput-formControl {
    margin-top: 0;
  }
}

.react-autosuggest__container {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: white;
    color: $blackish;

    li > div {
      padding: 2px 5px;

      & > span {
        padding: 2px;
      }
    }
  }
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  left: 0;
  // right: 0;
  z-index: 50;
  max-height: 250px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  div[class*='disabled'] {
    padding-left: 20px;
  }
}
