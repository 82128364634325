@font-face {
  font-family: 'ITC Franklin Gothic LT';
  src: url('../fonts/ITCFranklinGothicStdBook.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Franklin Gothic LT';
  src: url('../fonts/ITCFranklinGothicStdBook.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
