@import url(https://fonts.googleapis.com/css?family=Noto+Sans:regular,bold,italic,semibold);

html,
body,
#root,
#root > div {
  color: $blackish;
}

body {
  margin: 0;
  font-family: 'Noto Sans', 'Helvetica', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light-grey !important;
  color: $blackish;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.subnav {
  position: relative !important;
  background-color: white !important;
  border-top: 1px solid $grey;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;

  .title {
    color: $blackish;
  }

  .back-button {
    margin: 5px 0;
  }

  & > div {
    min-height: auto;
  }
}

.paper-padding {
  padding: 20px;
}

#chart-tooltip {
  display: none;
  position: absolute;
  padding: 3px 6px;
  border-radius: 3px;
  background-color: $blackish;
  color: #fff;
}

.isDevelopmentBanner {
  background: #aa0000 !important;
  text-align: center;
  padding: 40px;
  color: white;
  margin-top: 55px;

  a {
    color: white;
    font-size: 120%;

    &:hover {
      color: black;
    }
  }
}
