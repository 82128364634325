$primary: rgb(20, 10, 154);
$primary-darker: #0e205f;
$primary-light: rgb(60, 63, 139);
$primary-lighter: rgba(137, 143, 199, 0.4);
$secondary: gold;
//$secondary: rgb(237, 170, 0);

// Castrol THEME
$primary-castrol: #00833b;
$primary-darker-castrol: #007132;
$primary-light-castrol: #00b350;
$primary-lighter-castrol: #00e667;
$secondary-castrol: #f5564a;

// Chevron THEME
$primary-chevron: #014B60;
$primary-darker-chevron: #000000;
$primary-light-chevron: #000000;
$primary-lighter-chevron: #000000;
$secondary-chevron: #f5564a;

$dimgrey: dimgrey;
$grey: #e4e8ec;
$dark-grey: rgb(199, 199, 199);
$light-grey: #f2f4f7;
$sky-blue: #8da8ff;

$border: #dedfe2; // when on white background
$border-on-dark: #3d4a96; // when on dark purple background
$dark-text: #596371;
$light-text: #8692a4;
$shadow: #dedfe2;

$black: rgb(24, 24, 24);
$blackish: rgb(66, 66, 66);

$error: #f44336;
$critical: #ff5050;
$action: #ff9ecf;
$healthy: #24b93a;
$caution: #ffb800;
$query: #87ceeb;
$normal: #98fb98;
$warning: #f17a35;

$forestgreen: rgb(5, 160, 5);
$lightgreen: rgb(35, 194, 35);
$green: rgb(27, 165, 27);

$divider-color: rgba(14, 18, 113, 0.4);
$divider-color-light: $light-grey;

// should be in vars.scss
$small-screen: 660px;
$large-screen: 1280px;
