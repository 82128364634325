@import 'styles/colors.scss';

.not-found {
  margin: 50px 0;
  text-align: center;

  &.dark {
    .title {
      color: $blackish;
    }
  }

  .icon-container svg {
    width: 80px;
    height: 80px;
    color: $primary-light;
  }

  .title {
    margin-top: 15px;
  }
}

.loading-container {
  padding: 30px;
  height: 100%;
  position: relative;

  svg {
    color: $sky-blue;
  }
}
