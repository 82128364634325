.chevron {
  .nav-bar {
    .menu-button,
    .menu-button .text,
    .page-title {
      color: $primary-chevron !important;
    }
  }

  .menu,
  .main-menu {
    justify-content: stretch;
    display: flex;
    // margin-left: 30px;
    // width: 100%;
    // max-width: 1170px;
    // margin: 0 auto;
    .main-menu-inner {
      margin: 0 auto;
      padding: 0 15px;
      justify-content: space-evenly;
      @include breakpoint(xl, down) {
        max-width: 1170px;
      }
      @include breakpoint(lg, down) {
        max-width: 1140px;
      }
      @include breakpoint(md, down) {
        max-width: 960px;
      }
      @include breakpoint(sm, down) {
        max-width: 768px;
      }
    }
    div[class*='buttons---btn-group']:hover,
    div[class*='buttons---btn-group'] [class*='dropdown---open'],
    a:hover,
    li:hover,
    button.active {
      background-color: $primary-chevron !important;
    }
  }

  .settings-menu ul {
    & > div:hover,
    & > a:hover {
      background-color: $primary-chevron !important;
    }
  }

  .login,
  .change-password,
  .reset-password {
    background: #f7f7f7;

    .reset-password-link {
      margin: 0;
      margin-bottom: 30px;
      color: $primary-light-chevron;
      font-size: 0.8rem;
    }

    .title {
      color: $primary-light-chevron;
    }

    .reset-password-title {
      color: $primary-light-chevron;
    }

    .labcheck-links {
      font-size: 0.8rem;
      margin-top: 3em;
      a {
        color: $primary-light-chevron;
      }
    }

    .input {
      label {
        color: $primary-light-chevron;
        font-weight: 500;
      }
      input {
        color: black;
      }

      div::before {
        border-color: $primary-light !important;
      }

      div::after {
        border-color: $secondary !important;
      }
    }

    .error {
      margin-bottom: 10px;
      // margin-left: $left-margin;
      color: #f44336; // to colors?
      font-weight: 500;
      font-size: 15px;
    }

    .input {
      div::before {
        border-color: $primary-light-chevron !important;
      }

      div::after {
        border-color: $secondary-chevron !important;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0 30px $primary-light-chevron inset !important;
      }
    }

    .submit-button,
    .outlined-button {
      color: $primary-light-chevron;
      border-color: $primary-light-chevron;
    }
  }

  .reset-password {
    background: #f7f7f7;

    .title {
      color: $primary-light-chevron;
    }

    .input {
      label {
        color: $primary-light-chevron;
        font-weight: 500;
      }
      input {
        color: black;
      }

      div::before {
        border-color: $primary-light !important;
      }

      div::after {
        border-color: $secondary !important;
      }
    }

    .error {
      margin-bottom: 10px;
      // margin-left: $left-margin;
      color: #f44336; // to colors?
      font-weight: 500;
      font-size: 15px;
    }
    .input {
      div::before {
        border-color: $primary-light-chevron !important;
      }

      div::after {
        border-color: $secondary-chevron !important;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0 30px $primary-light-chevron inset !important;
      }
    }

    .sub-title {
      color: $primary-light-chevron;
    }
  }
  .sidebar-items {
    background: $primary-chevron;
    background: linear-gradient(to right, $primary-chevron 0%, $primary-darker-chevron 100%);

    .avatar-wrapper {
      border-color: $primary-light-chevron !important;
    }

    .settings p {
      color: #3ab972 !important;
    }

    .divider {
      background-color: $primary-light-chevron;
    }
  }

  .sidebar-subheader,
  .logout-button {
    color: $primary-light-chevron !important;
  }

  .not-found svg {
    color: $primary-light-castrol !important;
  }

  .loading-container svg {
    color: $secondary-chevron;
  }
}
