@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.subnav {
  z-index: initial !important;
  .toolbar {
    h6 {
      @include breakpoint(sm, down) {
        width: 70%;
      }
      a {
        color: $blackish;
      }
    }
    .back-button {
      padding-left: 2px !important;
      margin-right: 10px !important;
      box-shadow: none !important;

      @include breakpoint(sm, down) {
        color: black !important;
        padding: 4px 4px 1px 4px !important;
        min-width: auto !important;
        background: white !important;
      }
    }
    @include breakpoint(sm, down) {
      flex-wrap: wrap;
      padding: 0px;
    }
  }
}
