@import '../colors.scss';
@import '../mixins.scss';

.table {
  overflow: hidden;

  td,
  th {
    padding: 4px 8px;
    border-color: $grey;
  }

  thead {
    th {
      background-color: #f7f7f7;
      svg,
      span {
        color: $dark-text !important;
      }
      svg {
        opacity: 0.1;
      }
      .checkbox svg {
        opacity: 1;
      }
    }
  }

  & > tbody {
    &  > tr:not(.empty):hover th,
    & > tr:not(.empty):hover td {
      background-color: $light-grey;
      cursor: pointer;
    }
  }
}

.responsive-table {
  overflow: auto;
  margin-top: 20px;

  @include breakpoint(sm, down) {
    .table-container {
      box-shadow: none;
      tr {
        margin-top: 10px;
        display: block;
        background-color: white;
        border-color: $border;
        border-top: 1px solid $border;
        border-left: 1px solid $border;
        border-right: 1px solid $border;
      }
    }
  }

  td,
  th {
    padding: 4px 8px;
    border-color: $grey;
  }

  thead {
    th {
      background-color: #f7f7f7;
      svg,
      span {
        color: $dark-text !important;
      }
    }
    @include breakpoint(sm, down) {
      display: none;
    }
  }

  .status {
    color: white;

    span {
      @include breakpoint(sm, down) {
        color: #e6e6e6;
      }
    }
  }

  .mobile-title {
    display: none;
    margin-right: 5px;
    color: #5d5d5d;
    font-weight: lighter;

    @include breakpoint(sm, down) {
      display: inline-block;
    }
  }

  tr {
    &.critical {
      background-color: $critical;
    }
  }

  td,
  th {
    @include breakpoint(sm, down) {
      display: block;
      text-align: left !important;
    }
  }

  .check-icon {
    width: 18px;
    color: $forestgreen;
  }

  .pagination {
    background-color: #f7f7f7;
    overflow: hidden;
    color: $dark-text;

    svg {
      color: $dark-text;
    }
  }
}
