@import 'styles/colors.scss';

.komatsu-input {
  fieldset {
    border-color: rgb(157, 169, 185);
  }

  div:hover fieldset {
    border: 1px solid $grey !important;
  }

  div[class*='-focused'] fieldset {
    border: 1px solid rgba(60, 63, 139, 0.35) !important;
  }

  input {
    background: white !important;
  }
}
