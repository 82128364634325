@import 'styles/colors.scss';

.health-indicator {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 30px;
  margin-right: 30px;
  padding: 5px;
  text-align: center;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #fff;

  .inner {
    position: absolute;
    right: 0;
    top: 0;
    left: 5px;
    bottom: 0;
    writing-mode: tb-rl;
    transform: rotate(180deg);
  }
}
