.share-button {
  min-width: 40px !important;

  .icon {
    margin-right: 5px;
    font-size: 1rem;
  }
}

.copied-text {
  padding: 1px 6px;
}
